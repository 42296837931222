import React from 'react'

import { Typography } from '@mui/material'

const Section2Title = () => {
	return (
		<Typography
			marginY={'2rem'}
			variant={'overline'}
			data-testid={'section-2-title'}
		>
			2. FACILITATION TECHNIQUE
		</Typography>
	)
}

export default Section2Title
