import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import { Button, InputAdornment, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { BaseTextField } from 'components/Form'

import { styles } from './Section1.styles'

export const TEST_ID = 'leap-frog-section1'

export type Props = {
	companyProblem: string
	createPrompt: () => void
	editable: boolean
	generateScenarioDisabled: () => boolean
	innovationCompany: string
	isLoading: boolean
	resetAllField: () => void
	setCompanyProblem: (val: string) => void
	setInnovationCompany: (val: string) => void
}

const Section1 = ({
	innovationCompany,
	setInnovationCompany,
	companyProblem,
	setCompanyProblem,
	generateScenarioDisabled,
	resetAllField,
	isLoading,
	createPrompt,
	editable,
}: Props) => {
	return (
		<>
			<Typography
				marginY={'2rem'}
				color="black"
				sx={{ opacity: '87%', fontSize: '15px' }}
			>
				1. INNOVATION TOPIC
			</Typography>
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Typography color="black" sx={{ opacity: '87%' }}>
						What company are you brainstorming about today?{' '}
						<span style={{ color: '#D32F2F' }}>*</span>
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{ style: styles.inputLabel }}
						variant="outlined"
						data-testid={`${TEST_ID}__company-name-input`}
						label="Enter company name here."
						value={innovationCompany}
						onChangeValue={(val) => setInnovationCompany(val)}
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Typography color="black" sx={{ opacity: '87%' }}>
						Tell us briefly about a business problem you’re facing
						(within 5-10 words).
						<span style={{ color: '#D32F2F' }}>*</span>
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-problem-input`}
						label="Enter a business problem here."
						onChangeValue={(val) => setCompanyProblem(val)}
						value={companyProblem}
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Box
						display="flex"
						sx={{
							flexDirection: {
								xs: 'column-reverse',
								sm: 'row',
							},
						}}
						justifyContent="flex-start"
						gap="2rem"
					>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__create-prompt-button`}
							disabled={generateScenarioDisabled() || isLoading}
							onClick={createPrompt}
						>
							START IDEATING
						</Button>
						<Button
							disabled={!editable}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__reset-all-button`}
							onClick={resetAllField}
						>
							RESET ALL FIELDS
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Section1
