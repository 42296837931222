import axios, { AxiosResponse } from 'axios'

import {
	BattleshipCompetitorResponse,
	BattleshipCompetitorResponseResponse,
	BattleshipPrompt,
	BattleshipPromptResponse,
	BattleshipResponse,
	BattleshipResponseCompetitor,
	BattleshipResponseCompetitorResponse,
	BattleshipResponseResponse,
	ConnectFourResponse,
	ConnectFourResponseResponse,
	DressUpPrompt,
	DressUpPromptResponse,
	DressUpResponse,
	DressUpResponseResponse,
	ImageResponse,
	ProcessImageRequest,
	RoundRobinPrompt,
	RoundRobinPromptResponse,
	RoundRobinResponse,
	RoundRobinResponseResponse,
	SimonSaysPromptResponse,
	SimonSaysPrompts,
	SlotMachinePrompt,
	SlotMachinePromptResponse,
	SlotMachineResponse,
	SlotMachineResponseResponse,
	TeeterTotterResponse,
	TeeterTotterResponseResponse,
} from '../models/ideaModels'
import { getSignedRequest } from '../utils/authUtils'

export const postSlotMachinePrompt = async (
	data: SlotMachinePrompt
): Promise<SlotMachinePromptResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/slotMachinePrompt',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/slotMachinePrompt',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postProcessImage = async (
	data: ProcessImageRequest
): Promise<ImageResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/processImage',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/processImage',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postSlotMachineResponse = async (
	data: SlotMachineResponse
): Promise<SlotMachineResponseResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/slotMachineResponse',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/slotMachineResponse',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const getPromptResponse = async (
	aiPrompt: string
): Promise<AxiosResponse> => {
	// convert to json for now
	const data = {
		aiPrompt,
	}
	const signed = await getSignedRequest(
		'POST',
		'ai/process-chat-prompt',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/process-chat-prompt',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postBattleshipPrompt = async (
	data: BattleshipPrompt
): Promise<BattleshipPromptResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/battleShipsPrompt',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/battleShipsPrompt',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postBattleshipResponse = async (
	data: BattleshipResponse
): Promise<BattleshipResponseResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/battleShipsResponse',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/battleShipsResponse',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postBattleshipCompetitorResponse = async (
	data: BattleshipCompetitorResponse
): Promise<BattleshipCompetitorResponseResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/battleShipsCompetitor',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/battleShipsCompetitor',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postBattleshipResponseToCompetitor = async (
	data: BattleshipResponseCompetitor
): Promise<BattleshipResponseCompetitorResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/battleShipsResponseCompetitor',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/battleShipsResponseCompetitor',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postConnectFourResponse = async (
	data: ConnectFourResponse
): Promise<ConnectFourResponseResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/connectFourResponse',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/connectFourResponse',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postDressUpPrompt = async (
	data: DressUpPrompt
): Promise<DressUpPromptResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/dressUpPrompt',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/dressUpPrompt',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postDressUpResponse = async (
	data: DressUpResponse
): Promise<DressUpResponseResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/dressUpResponse',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/dressUpResponse',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postTeeterTotterResponse = async (
	data: TeeterTotterResponse
): Promise<TeeterTotterResponseResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/teeterTotterResponse',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/teeterTotterResponse',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postRoundRobinPrompt = async (
	data: RoundRobinPrompt,
	pathURL: string
): Promise<RoundRobinPromptResponse> => {
	const signed = await getSignedRequest(
		'POST',
		`ai/roundRobin${pathURL}`,
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`ai/roundRobin${pathURL}`,
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postRoundRobinResponse = async (
	data: RoundRobinResponse
): Promise<RoundRobinResponseResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'ai/roundRobinResponse',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'ai/roundRobinResponse',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const postSimonSaysPrompt = async (
	data: SimonSaysPrompts
): Promise<SimonSaysPromptResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'randomprompts/simonSays',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'randomprompts/simonSays',
			data,
		})
	} else {
		throw Error('Unable to get signed request')
	}
}
