/* istanbul ignore file */
import { useEffect, useState } from 'react'

import { Box, Container } from '@mui/material'
import logger from 'utils/logger'
import { ShowWarningModal, UseBlocker } from 'utils/useBlocker'

import { TeeterTotterModel } from 'models/TeeterTotterModel'
import { Game } from 'models/gameModels'
import { TeeterTotterPrompt } from 'models/ideaModels'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import { postTeeterTotterResponse } from 'services/completions.service'
import { getTeeterTotterRandomPrompts, postIdea } from 'services/ideas.service'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'
import Hero from 'components/Hero'

import useCreateCustomGameModal from 'hooks/useCreateCustomGameModal'

import { alertTitleTextMap, defaultErrorMessage } from 'assets/alertText'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import Section1 from './components/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'

export const TEST_ID = 'teeter-totter'

const TeeterTotter = ({ game }: { game?: Game }) => {
	const { setIdeaStreakCount } = useIdeaStreakContext()
	const [submitIdeas, setSubmitIdeas] = useState(false)
	const [ideaPrompt, setIdeaPrompt] = useState(false)
	useEffect(() => {
		if (game) {
			setInnovationCompany(game.innovationCompany)
			setCompanyProblem(game.innovationTopic)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const {
		authorIdeation,
		centerCategory,
		companyProblem,
		gameToJSON,
		generatePromptsDisabled,
		submitIdeasDisabled,
		setGameId,
		termsAgreed,
		setTermsAgreed,
		innovationCompany,
		innovationTopic,
		leftExtreme,
		reset,
		rightExtreme,
		setAuthorIdeation,
		setCenterCategory,
		setCompanyProblem,
		setInnovationCompany,
		setInnovationTopic,
		setLeftExtreme,
		setRightExtreme,
		getRandomPromptsQueryObj,
		setPreviousPrompts,
		resetSoft,
		responseToJSON,
		responseConversation,
		setResponseConversation,
		setCurrentResponses,
		currentResponses,
	} = TeeterTotterModel()
	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const { showAlert } = useToastContext()
	const { showCreateCustomGameModal } = useCreateCustomGameModal()
	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)
	const [isAiLoading, setIsAiLoading] = useState(false)
	const [aiExampleVisible, setAiExampleVisible] = useState<boolean>(false)

	const userMessage = {
		content: '',
		role: 'user',
	}

	const updateAIResponse = (content: string) => {
		return {
			content,
			role: 'assistant',
		}
	}

	useEffect(() => {
		if (game) {
			setInnovationCompany(game.innovationCompany)
			setCompanyProblem(game.innovationTopic)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const { showModal } = useModalContext()
	const fields = [authorIdeation]
	const blocker = UseBlocker(fields, submitIdeas)

	useEffect(() => {
		setIdeaPrompt(authorIdeation.length >= 8)
	}, [authorIdeation])

	useEffect(() => {
		if (!submitIdeas) {
			return ShowWarningModal(blocker, showModal)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker])

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (ideaPrompt) {
				event.preventDefault()
			}
		}

		if (ideaPrompt) {
			window.addEventListener('beforeunload', handleBeforeUnload)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [ideaPrompt])

	const handleAiResponseClick = async (
		additionalProps: TeeterTotterPrompt,
		isUserInitiated = true
	) => {
		if (isUserInitiated && !aiExampleVisible) {
			setAiExampleVisible(true)
			return
		}

		setIsAiLoading(true)
		try {
			const prompt = responseToJSON()
			prompt.conversation = [...responseConversation, userMessage]
			if (additionalProps.promptId) {
				prompt.prompt = additionalProps
			}
			const { status, data } = await postTeeterTotterResponse(prompt)

			if (status === 200 && data) {
				const msg = JSON.stringify(data)
				const updatedConversation = [
					...responseConversation,
					userMessage,
					updateAIResponse(msg),
				]

				setResponseConversation(updatedConversation)
				setCurrentResponses(data)
			} else {
				throw new Error(`Unexpected response: ${JSON.stringify(data)}`)
			}
		} catch (error) {
			logger.error('Error in AI response handling:', error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsAiLoading(false)
		}
	}

	const showWarningModal = async () => {
		if (
			step === GAME_STEP.Step2 &&
			!submitIdeas &&
			!!authorIdeation.length
		) {
			return ShowWarningModal(
				{
					state: 'blocked',
					location: {
						pathname: '',
						search: '',
						hash: '',
						state: null,
						key: 'kxdgy6c2',
					},
					reset: () => logger.debug('reset'),
					proceed: () => handleRandomPrompts(),
				},
				showModal
			)
		}

		handleRandomPrompts()
	}

	const handleRandomPrompts = async () => {
		if (
			step === GAME_STEP.Step2 &&
			!submitIdeas &&
			!!authorIdeation.length
		) {
			showWarningModal()
		}

		try {
			resetSoft()
			setAiExampleVisible(false)
			setIsLoading(true)
			setStep(GAME_STEP.Step2)

			const { status, data } = await getTeeterTotterRandomPrompts(
				getRandomPromptsQueryObj()
			)

			if (status === 200 && data.prompt) {
				const { prompt } = data

				setLeftExtreme(prompt.leftConstraint)
				setCenterCategory(prompt.topic)
				setRightExtreme(prompt.rightConstraint)

				setIsAiLoading(true)
				handleAiResponseClick(prompt, false)

				const updatedPreviousPrompts = prompt.previousPrompts
				updatedPreviousPrompts.push(Number(prompt.promptId))
				setPreviousPrompts(updatedPreviousPrompts)
			} else {
				throw new Error(`Unexpected response: ${JSON.stringify(data)}`)
			}
		} catch (error) {
			logger.error('Error fetching random prompts:', error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const resetAllField = () => {
		setInnovationCompany('')
		setInnovationTopic('')
		setCompanyProblem('')
	}

	const handleSubmitIdeas = async () => {
		setIsSubmitButtonLoading(true)
		setIsLoading(true)
		setSubmitIdeas(true)

		try {
			const response = await postIdea(gameToJSON())

			if (showAlert) {
				showAlert({
					severity:
						response.status === 200
							? ToastSeverity.SUCCESS
							: ToastSeverity.ERROR,
				})
			}
			if (response.status === 200) {
				showCreateCustomGameModal({
					gameLabel: GAME_TYPE.TEETER_TOTTER.label,
					gameState: {
						gameTypeId: GAME_TYPE.TEETER_TOTTER.value,
						innovationTopic,
						innovationCompany,
						companyProblem,
					},
				})
			}
			setIdeaStreakCount((prevState) => prevState + 1)
		} catch (error) {
			logger.error(error)
			if (showAlert) {
				showAlert({
					title: alertTitleTextMap.submitIdeaError.title,
					bodyText: alertTitleTextMap.submitIdeaError.text,
					severity: ToastSeverity.ERROR,
				})
			}
		} finally {
			setIsLoading(false)
			setIsSubmitButtonLoading(false)
			reset()
			handleRandomPrompts()
			setIdeaStreakCount((prevState) => prevState + 1)

			window.scrollTo({
				top: 0,
			})
		}
	}

	return (
		<Box>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.TEETER_TOTTER}
				innovationCompany={innovationCompany}
				setInnovationCompany={setInnovationCompany}
				innovationTopic={innovationTopic}
				setInnovationTopic={setInnovationTopic}
				companyProblem={companyProblem}
				setCompanyProblem={setCompanyProblem}
				isLoading={isLoading}
				createPrompt={() => {}}
			/>
			<Container
				data-testid={`${TEST_ID}-container`}
				maxWidth={step === GAME_STEP.Step1 ? 'md' : 'lg'}
				sx={{
					paddingTop: '3rem',
					paddingBottom: '3rem',
					paddingX: '2rem',
				}}
			>
				<Box paddingBottom={'2rem'}>
					<BreadCrumbs
						breadcrumbs={[
							{
								label: '1. INNOVATION TOPIC',
								active: step === GAME_STEP.Step1,
								onClick:
									step !== GAME_STEP.Step1
										? () => setStep(GAME_STEP.Step1)
										: undefined,
							},
							{
								label: '2. FACILITATION TECHNIQUE & 3. IDEATION',
								active: step !== GAME_STEP.Step1,
							},
						]}
					/>
				</Box>
				{game && (game.gameTitle || game.endTime) && (
					<CustomGameTitleBar game={game} />
				)}
				{step === GAME_STEP.Step1 && (
					<Section1
						isLoading={isLoading}
						innovationCompany={innovationCompany}
						setInnovationCompany={setInnovationCompany}
						companyProblem={companyProblem}
						setCompanyProblem={setCompanyProblem}
						resetAllField={resetAllField}
						createPrompt={() => handleRandomPrompts()}
						generatePromptsDisabled={generatePromptsDisabled}
						innovationTopic={innovationTopic}
						setInnovationTopic={setInnovationTopic}
						editable={
							game
								? game.gameTitle === '' && game.endTime === ''
								: true
						}
					/>
				)}
				{step === GAME_STEP.Step2 && (
					<>
						<Section2
							isLoading={isLoading}
							onRefreshPromptClick={() => handleRandomPrompts()}
							leftCardValue={leftExtreme}
							centerCardValue={centerCategory}
							rightCardValue={rightExtreme}
							currentResponses={currentResponses}
							isAiLoading={isAiLoading}
						/>
						<Section3
							isLoading={isSubmitButtonLoading}
							authorIdeation={authorIdeation}
							setAuthorIdeation={setAuthorIdeation}
							onSubmitIdeaClick={handleSubmitIdeas}
							onViewIdeasClick={() => {}}
							termsAgreed={termsAgreed}
							setTermsAgreed={setTermsAgreed}
							gameId={game?.gameId}
							submitIdeasDisabled={submitIdeasDisabled}
						/>
					</>
				)}
			</Container>
		</Box>
	)
}

export default TeeterTotter
