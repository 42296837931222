import { Theme } from '@mui/material/styles'

import { palette } from './palette'

interface StyleOverrides {
	[key: string]: (props: { theme: Theme }) => object
}

interface Components {
	[key: string]: {
		defaultProps?: object
		styleOverrides?: StyleOverrides
	}
}

export const components: Components = {
	MuiButtonGroup: {
		styleOverrides: {
			root: ({ theme }) => ({
				'&.MuiButtonGroup-root': {
					border: `${theme.palette.secondary.main} 1px solid`,
					boxShadow: 'none',
				},
				'& .MuiButtonGroup-grouped': {
					backgroundColor: '#FFF',
					borderColor: palette.secondary.main,
					color: palette.secondary.light,
					'&:hover': {
						backgroundColor: '#F5F5F5',
					},
					height: '44px',
				},
			}),
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: ({ theme }) => ({
				'&.MuiInputLabel-root': {
					color: theme.palette.secondary.main,
					fontWeight: '700',

					'&.Mui-error': {
						color: theme.palette.error.light,
					},

					'&.MuiInputLabel-shrink': {
						fontWeight: '400',

						'&.Mui-focused': {
							color: theme.palette.primary.main,

							'&.Mui-error': {
								color: theme.palette.error.main,
							},
						},

						'&:not(.Mui-focused)': {
							color: theme.palette.secondary.main,

							'&.Mui-error': {
								color: theme.palette.error.main,
							},
						},
					},
				},
			}),
		},
	},
	MuiFormControlLabel: {
		styleOverrides: {
			root: ({ theme }) => ({
				// ugh we have to have the checkbox styling here yuck
				'&.Mui-error .MuiCheckbox-root': {
					color: theme.palette.error.main,
					'&.Mui-checked': {
						color: theme.palette.error.main,
					},
					'&.Mui-disabled': {
						color: theme.palette.error.light,
					},
				},
				'&.Mui-error .MuiFormControlLabel-label': {
					color: theme.palette.error.main,
					'&.Mui-disabled': {
						color: theme.palette.error.light,
					},
				},
			}),
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: ({ theme }) => ({
				'&.MuiFormHelperText-root': {
					color: theme.palette.secondary.main,
				},
				'&.Mui-error': {
					color: theme.palette.error.main,
				},
			}),
		},
	},
	MuiButton: {
		defaultProps: {
			disableRipple: true,
		},
		styleOverrides: {
			root: () => ({
				'&': {
					borderRadius: '4px',
					height: 40,
				},
			}),
			primary: () => ({
				'&:disabled': {
					backgroundColor: 'primary.disabled',
					color: '#FFF',
				},
				'&:hover': {
					backgroundColor: 'primary.hover',
					color: '#FFF',
				},
				backgroundColor: 'primary.main',
				borderRadius: '0px',
				boxShadow: 'none',
				color: '#FFF',
				maxWidth: '200rem',
				padding: '1.2rem 3rem',
			}),
			secondary: () => ({
				'&:disabled': {
					backgroundColor: 'secondary.disabled',
					color: '#FFF',
				},
				'&:hover': {
					backgroundColor: 'secondary.hover',
					color: '#FFF',
				},
				backgroundColor: 'secondary.main',
				borderRadius: 0,
				color: '#FFF',
				pl: '2.2rem',
				pr: '2.2rem',
			}),
		},
	},
	MuiIconButton: {
		styleOverrides: {
			root: ({ theme }) => ({
				'&:hover': {
					backgroundColor: 'transparent',
					fontWeight: theme.typography.fontWeightBold,
				},
				color: theme.palette.secondary.main,
				fontSize: theme.typography.body1.fontSize,
				fontWeight: theme.typography.fontWeightRegular,
				textDecoration: 'underline',
				textTransform: 'none',
				textUnderlineOffset: '.22em',
			}),
		},
	},
	MuiCheckbox: {
		styleOverrides: {
			root: ({ theme }) => ({
				'&.MuiCheckbox-root': {
					color: theme.palette.secondary.main,
					'&.Mui-checked': {
						color: theme.palette.secondary.main,
					},
					'&.Mui-disabled': {
						color: theme.palette.secondary.light,
					},
				},
			}),
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: ({ theme }) => ({
				minHeight: '2em',

				'@media (min-width: 600px)': {
					minHeight: '2em',
				},

				'&.Mui-root': {
					backgroundColor: '#DBE0E4',
					color: theme.palette.secondary.main,
					fontWeight: 700,
				},
				'&:hover': {
					backgroundColor: palette.dropDownColor.hover,
				},
				'&.Mui-selected, &.Mui-selected:hover': {
					backgroundColor: '#DBE0E4',
					color: theme.palette.secondary.main,
					fontWeight: 700,
				},
			}),
		},
	},
	MuiSelect: {
		styleOverrides: {
			root: ({ theme }) => ({
				'&.MuiInputBase-root': {
					height: 52,
					boxSizing: 'border-box',
					fontWeight: '700',
					minWidth: '26rem', // TODO: remove hardcoded min width once mobile refactor is complete
				},
				'&.MuiOutlinedInput-root': {
					'& .MuiOutlinedInput-notchedOutline': {
						transition: 'border-color 0.1s',
						borderColor: theme.palette.secondary.main,
						borderWidth: '2px',
						borderRadius: 0,
					},

					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.primary.main,
					},

					'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.disabledColor.main,
					},

					'&.Mui-error': {
						backgroundColor: theme.palette.errorDisabledColor.main,

						'.MuiOutlinedInput-notchedOutline': {
							borderColor: theme.palette.error.main,
						},
					},
				},
				'& .MuiSvgIcon-root': {
					color: theme.palette.secondary.main,
					fontSize: '3.6rem',
				},
				'&.Mui-disabled .MuiSvgIcon-root': {
					color: theme.palette.secondary,
				},
			}),
		},
	},
	MuiTextField: {
		styleOverrides: {
			root: ({ theme }) => ({
				'& .MuiInputBase-input': {
					height: 52,
					boxSizing: 'border-box',
					fontWeight: '700',

					// styling min-width here for datetimepicker
					'&.MuiInputBase-inputAdornedEnd': {
						minWidth: '20rem',
					},
				},
				'& .MuiFilledInput-underline': {
					'&:before': {
						// hate using !important here, but styling over other styles is a pain
						borderBottomWidth: '2px !important',

						// TODO: see if we can overwrite this color in MUI palette
						borderBottomColor: theme.palette.secondary.main,
					},
					'&.Mui-disabled:before': {
						borderBottomStyle: 'solid',
						borderBottomColor: theme.palette.disabledColor?.main,
					},

					'&.Mui-error': {
						backgroundColor: theme.palette.errorDisabledColor.main,

						'&:before': {
							borderBottomColor: theme.palette.error.main,
						},
						'&.Mui-disabled:before': {
							borderBottomColor: theme.palette.error.light,
						},
					},
				},
				'& .MuiOutlinedInput-root': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderWidth: '2px',
						borderRadius: '4px',
						transition: 'border-color 0.1s',
						borderColor: theme.palette.secondary.main,
					},
					'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.disabledColor?.main,
					},
					'&.Mui-error': {
						backgroundColor: theme.palette.errorDisabledColor.main,
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.palette.error.main,
						},
					},
				},
				'& .MuiInputAdornment-root': {
					color: theme.palette.secondary.main,
				},
				'&.Mui-disabled .MuiInputAdornment-root': {
					color: theme.palette.disabledColor?.main,
				},
				'& .MuiInputBase-readOnly .MuiInputAdornment-root .MuiButtonBase-root':
					{
						color: theme.palette.secondary.main,
					},
			}),
		},
	},
	MuiTypography: {
		defaultProps: {
			variantMapping: {
				overline: 'p',
			},
		},
	},
	MuiBreadcrumbs: {
		styleOverrides: {
			root: () => ({
				'& .css-1wfv9t8-MuiTypography-root': {
					'&:hover': {
						cursor: 'pointer',
					},
				},
			}),
		},
	},
}
