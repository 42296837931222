import React, {
	Dispatch,
	ReactNode,
	createContext,
	useContext,
	useState,
} from 'react'

type IdeaStreakContextType = {
	ideaStreak: number
	setIdeaStreakCount: Dispatch<React.SetStateAction<number>>
}

type IdeaStreakProvider = {
	children: ReactNode
}

const IdeaStreakContext = createContext<IdeaStreakContextType | undefined>(
	undefined
)

export const IdeaStreakProvider = ({ children }: IdeaStreakProvider) => {
	const [ideaStreak, setIdeaStreakCount] = useState(0)
	return (
		<IdeaStreakContext.Provider value={{ ideaStreak, setIdeaStreakCount }}>
			{children}
		</IdeaStreakContext.Provider>
	)
}

export const useIdeaStreakContext = (): IdeaStreakContextType => {
	const context = useContext(IdeaStreakContext)
	if (context === undefined) {
		throw new Error(
			'useIdeaStreakContext must be used within an IdeaStreakContextProvider'
		)
	}
	return context
}
