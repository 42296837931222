export type conversation = {
	content: string
	role: string
}

export const userMessage = {
	content: '',
	role: 'user',
}

type gameTitle = {
	gameTitle: string
}

type author = {
	authorName: string
}

type metadata = {
	page: number
	pageSize: number
	totalCount: number
}

export type Idea = {
	authorIdeation: string
	companyName: string
	createdDate: Date
	firstName: string
	gameTitle: string
	gameType: string
	gameTypeId: number
	innovationCompany: string
	innovationTopic: string
	innovationTopicManual: string
	isFavorite: boolean
	lastName: string
	submittedId: string
	updatedTime: Date
}

export type SlotMachinePrompt = {
	conversation: Array<conversation>
	customerJourney?: string
	innovationCompany: string
	innovationTopic: string
	previousRandomElements: Array<string>
}

export type SlotMachineResponse = {
	conversation: Array<conversation>
	customerProblem: string
	customerType: string
	innovationCompany: string
	innovationTopic: string
	randomElement: string
}
export type SlotMachineResponseResponse = {
	data: {
		explanation: string
		responses: Array<string>
	}
	status: number
}
export type SlotMachinePromptResponse = {
	data: {
		companyName: string
		customerProblem: string
		customerType: string
		randomElement: string
		resetPreviousElements: boolean
	}
	status: number
}
export type ProcessImageRequest = {
	customerProblem: string
	gameTypeId: number
	randomElement: string
}

export type PostIdea = {
	authorIdeation: string
	gameId?: string
	gameTypeId: number
	innovationCompany: string
	innovationTopic?: string
	innovationTopicManual?: string
	isFavorite?: boolean
}

export type SubmittedIdeaCSV = {
	authorIdeation: string
	companyName: string
	createdDate: Date
	firstName: string
	gameId?: string
	gameTitle: string
	gameType: string
	gameTypeId: number
	innovationCompany: string
	innovationTopic: string
	innovationTopicManual: string
	isFavorite: boolean
	lastName: string
	promptTitle?: string
	submittedId: string
	updatedTime: Date | null
}

export type PostIdeaResponse = {
	status: number
}

export type Ideas = {
	metadata: metadata
	records: Array<Idea>
}

export type Lookups = {
	authors: author[]
	gameTitles: Array<gameTitle>
}

export type RandomPrompts = {
	limit: number
	previousPrompts: Array<string>
}

export type RandomPromptsTeeterTotter = {
	previousPrompts: number[]
}

export type RandomPromptsResponse = {
	data: {
		prompts: Array<string>
		resetPreviousPrompts: boolean
	}
	status: number
}

export type TeeterTotterPrompt = {
	leftConstraint: string
	previousPrompts: number[]
	promptId: string
	rightConstraint: string
	topic: string
}

export type RandomPromptsTeeterTotterResponse = {
	data: {
		prompt: TeeterTotterPrompt
	}
	status: number
}

export type BattleshipPrompt = {
	companyProblem?: string
	conversation: conversation[]
	innovationCompany: string
	innovationTopic: string
}

export type BattleshipPromptResponse = {
	data: {
		companyDisruption: string
	}
	status: number
}

export type BattleshipResponse = {
	companyDisruption: string
	conversation: conversation[]
	innovationCompany: string
	innovationTopic: string
}

export type BattleshipCompetitorResponse = {
	companyDisruption: string
	conversation: conversation[]
	innovationCompany: string
}

export type BattleshipCompetitorResponseResponse = {
	data: {
		competitorName: string
		responses: Array<string>
	}
	status: number
}

export type BattleshipResponseCompetitor = {
	aiScenarioResponse: string
	companyDisruption: string
	conversation: conversation[]
	innovationCompany: string
}

export type BattleshipResponseCompetitorResponse = {
	data: {
		competitorName: string
		responses: Array<string>
	}
	status: number
}

export type BattleshipResponseResponse = {
	data: {
		competitorName: string
		responses: Array<string>
	}
	status: number
}

export type ConnectFourResponse = {
	companyName: string
	companyProblem: string
	conversation: Array<conversation>
	currentPrompt: string
}

export type ConnectFourResponseResponse = {
	data: Record<string, { ideas: string[]; key: string }[]>
	status: number
}

export type DressUpPrompt = {
	companyProblem: string
	conversation: Array<conversation>
	innovationCompany: string
	innovationTopic: string
	previousCrossCompanyNames: Array<string>
}

export type DressUpPromptResponse = {
	data: {
		companyName: string
		crossCompanyName: string
		prompts: Array<string>
		resetPreviousCrossCompanys: boolean
	}
	status: number
}

export type DressUpResponse = {
	companyProblem: string
	conversation: Array<conversation>
	crossCompanyName: string
	innovationCompany: string
	innovationTopic: string
	prompt: string
}

export type TeeterTotterResponse = {
	companyProblem: string
	conversation: Array<conversation>
	innovationCompany: string
	innovationTopic: string
	prompt: TeeterTotterPrompt
}

export type TeeterTotterResponseResponse = {
	data: string
	status: number
}

export type DressUpResponseResponse = {
	data: string
	status: number
}

export type RoundRobinPrompt = {
	conversation: Array<conversation>
	innovationCompany: string
	innovationTopicManual: string
}

export type RoundRobinPromptResponse = {
	data: string
	status: number
}

export type RoundRobinResponse = {
	challengePrompt: string
	conversation: Array<conversation>
	factPrompt: string
	innovationCompany: string
	innovationTopicManual: string
	scalePrompt: string
	testingPrompt: string
}

export type RoundRobinResponseResponse = {
	data: {
		ideas: string[]
	}
	status: number
}

export type SimonSaysPrompts = {
	previousConnectionPoints: Array<string>
	previousPrompts: Array<number>
}

export type SimonSaysPrompt = {
	customerConnectionPoint: string
	customerProblem: string
	customerType: string
	promptId: number
}

export type SimonSaysPromptResponse = {
	data: {
		prompt: SimonSaysPrompt
		resetPreviousConnectionPoints: boolean
		resetPreviousPrompts: boolean
	}
	status: number
}

export type ImageResponse = {
	data: string
}
