import { sign } from 'aws4'
import { fetchAuthSession } from 'aws-amplify/auth'
import { Method } from 'axios'
import { OutgoingHttpHeaders } from 'http'
import logger from 'utils/logger'

import { LOCAL_STORAGE_KEYS } from 'assets/constants'

interface SignedRequest {
	body: string
	headers: Record<string, string>
	host: string
	method: Method
	path: string
	region: string
	service: string
}

export const navigateToRedirectUrl = () => {
	if (window.localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH)) {
		window.location.assign(
			window.localStorage.getItem(
				LOCAL_STORAGE_KEYS.REDIRECT_PATH
			) as string
		)
		window.localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH)
	} else {
		window.location.assign(window.location.origin)
	}
}

export const getSignedRequest = async (
	method: Method,
	path: string,
	body?: string
): Promise<SignedRequest | null> => {
	const functionUrl = process.env.REACT_APP_AWS_API_URL as string
	const { host } = new URL(functionUrl)
	const { tokens, credentials } = await fetchAuthSession()

	let signedRequest: SignedRequest | undefined

	if (credentials) {
		const { accessKeyId, sessionToken, secretAccessKey } = credentials
		const idToken = tokens?.idToken?.toString() ?? ''
		let headers: OutgoingHttpHeaders = {
			'Content-Type': 'application/json',
			token: idToken,
		}

		if (!body) {
			headers = {
				token: idToken,
			}
		}

		try {
			signedRequest = sign(
				{
					method,
					service: 'lambda',
					region: process.env.REACT_APP_AWS_REGION,
					host,
					path,
					headers,
					body,
				},
				{
					accessKeyId,
					secretAccessKey,
					sessionToken,
				}
			) as SignedRequest

			return signedRequest
		} catch (error) {
			logger.error('Error occurred in getSignedRequest: ', error)
			throw new Error('Get Signed Request error', error)
		}
	}

	return null
}

export const authEventHandler = (event: string) => {
	switch (event) {
		case 'signedIn':
			logger.debug('user has been signedIn successfully.')
			navigateToRedirectUrl()
			break
		case 'signedOut':
			logger.debug('user has been signedOut successfully.')
			break
		case 'tokenRefresh':
			logger.debug('auth tokens have been refreshed.')
			break
		case 'tokenRefresh_failure':
			logger.error('failure while refreshing auth tokens.')
			break
		case 'signInWithRedirect':
			logger.debug(
				'signInWithRedirect API has successfully been resolved.'
			)
			break
		case 'signInWithRedirect_failure':
			logger.error(
				'failure while trying to resolve signInWithRedirect API.'
			)
			break
		case 'customOAuthState':
			logger.debug('custom state returned from CognitoHosted UI')
			break
	}
}
