import React, { useEffect, useState } from 'react'

import { Button, Typography } from '@mui/material'
import { Box, useMediaQuery, useTheme } from '@mui/system'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import GeneratedExampleDropdown from 'components/GeneratedExampleDropdown'
import Section2Title from 'components/Section2Title'

import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import Node from '../Node'
import { styles } from './Section2.styles'

export const TEST_ID = 'teeter-totter-section2'

export type Props = {
	centerCardValue: string
	currentResponses: string
	isAiLoading: boolean
	isLoading: boolean
	leftCardValue: string
	onEditPromptClick?: (editCardValue: {
		center: string
		left: string
		right: string
	}) => void
	onRefreshPromptClick: () => void
	rightCardValue: string
}

const mobileCards = (
	isLoading: boolean,
	isEdit: boolean,
	leftCardValue: string,
	centerCardValue: string,
	rightCardValue: string,
	onInputChange: (oldValue: string, newValue: string) => void
) => {
	return (
		<Box
			sx={styles.cards}
			flexDirection={'column'}
			data-testid={`${TEST_ID}__mobile-cards`}
			alignItems={'center'}
		>
			<Box display="flex" width="176px" alignItems={'center'}>
				<Node
					isLoading={isLoading}
					positions={'center'}
					text={centerCardValue}
					isEdit={isEdit}
					onInputChange={onInputChange}
				/>
			</Box>
			{/* Down line */}
			<Box display={'flex'} justifyContent={'center'}>
				<svg
					width="2"
					height="43"
					viewBox="0 0 2 43"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<line
						x1="1"
						y1="43"
						x2="1"
						stroke="#C8003C"
						strokeWidth="2"
					/>
				</svg>
			</Box>
			<Box display={'flex'} flexDirection={'row'}>
				<Node
					isLoading={isLoading}
					positions={'left'}
					text={leftCardValue}
					isEdit={isEdit}
					onInputChange={onInputChange}
				/>
				{/* Left arrow */}
				<Box display={'flex'} alignItems={'center'}>
					<svg
						width="31"
						height="16"
						viewBox="0 0 31 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.684007 7.29289C0.293482 7.68342 0.293482 8.31658 0.684007 8.70711L7.04797 15.0711C7.43849 15.4616 8.07166 15.4616 8.46218 15.0711C8.85271 14.6805 8.85271 14.0474 8.46218 13.6569L2.80533 8L8.46218 2.34315C8.85271 1.95262 8.85271 1.31946 8.46218 0.928932C8.07166 0.538408 7.43849 0.538408 7.04797 0.928932L0.684007 7.29289ZM1.39111 9H33.8391V7H1.39111V9Z"
							fill="#C8003C"
						/>
					</svg>
				</Box>
				{/* Central line */}
				<Box display={'flex'} width={2} flexDirection={'column'}>
					<Box height="50%" borderRight={'2px solid #C8003C'}></Box>
				</Box>
				{/* Right arrow */}
				<Box display={'flex'} alignItems={'center'}>
					<svg
						width="31"
						height="16"
						viewBox="0 0 31 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M30.316 8.70711C30.7065 8.31658 30.7065 7.68342 30.316 7.29289L23.952 0.928932C23.5615 0.538408 22.9283 0.538408 22.5378 0.928932C22.1473 1.31946 22.1473 1.95262 22.5378 2.34315L28.1947 8L22.5378 13.6569C22.1473 14.0474 22.1473 14.6805 22.5378 15.0711C22.9283 15.4616 23.5615 15.4616 23.952 15.0711L30.316 8.70711ZM29.6089 7L0.390663 7V9L29.6089 9V7Z"
							fill="#C8003C"
						/>
					</svg>
				</Box>
				<Node
					isLoading={isLoading}
					positions={'right'}
					text={rightCardValue}
					isEdit={isEdit}
					onInputChange={onInputChange}
				/>
			</Box>
		</Box>
	)
}

const desktopAndTabletCards = (
	isLoading: boolean,
	isEdit: boolean,
	leftCardValue: string,
	centerCardValue: string,
	rightCardValue: string,
	onInputChange: (oldValue: string, newValue: string) => void
) => {
	return (
		<Box display={'flex'} data-testid={`${TEST_ID}__desktop-cards`}>
			<Node
				isLoading={isLoading}
				text={leftCardValue}
				positions={'left'}
				isEdit={isEdit}
				onInputChange={onInputChange}
			/>
			{/* Left arrow */}
			<Box display={'flex'} sx={styles.container}>
				<svg
					width="268"
					height="16"
					viewBox="0 0 270 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0.792892 7.29291C0.402374 7.68343 0.402374 8.3166 0.792893 8.70712L7.15686 15.0711C7.54738 15.4616 8.18054 15.4616 8.57108 15.0711C8.96159 14.6806 8.96159 14.0474 8.57108 13.6569L2.91422 8.00001L8.57107 2.34316C8.96159 1.95264 8.96159 1.31947 8.57107 0.928946C8.18054 0.538422 7.54738 0.538422 7.15686 0.928947L0.792892 7.29291ZM268.5 7L1.5 7.00001L1.5 9.00001L268.5 9L268.5 7Z"
						fill="url(#paint0_linear_8266_87768)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_8266_87768"
							x1="268.5"
							y1="8.00001"
							x2="2.49997"
							y2="6.00252"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FF437B" />
							<stop offset="1" stopColor="#C8003C" />
						</linearGradient>
					</defs>
				</svg>
			</Box>
			<Node
				isLoading={isLoading}
				positions={'center'}
				text={centerCardValue}
				isEdit={isEdit}
				onInputChange={onInputChange}
			/>
			{/* Right arrow */}
			<Box display={'flex'} sx={styles.container}>
				<svg
					width="268"
					height="16"
					viewBox="0 0 270 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M269.707 8.70711C270.098 8.31658 270.098 7.68342 269.707 7.29289L263.343 0.928933C262.953 0.538409 262.32 0.538409 261.929 0.928933C261.538 1.31946 261.538 1.95262 261.929 2.34315L267.586 8L261.929 13.6569C261.538 14.0474 261.538 14.6805 261.929 15.0711C262.32 15.4616 262.953 15.4616 263.343 15.0711L269.707 8.70711ZM269 7L0 7.00001L0 9.00001L269 9L269 7Z"
						fill="url(#paint0_linear_8415_82129)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_8415_82129"
							x1="269.382"
							y1="8.00001"
							x2="0.608556"
							y2="7.23793"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#C8003C" />
							<stop offset="1" stopColor="#FF437B" />
						</linearGradient>
					</defs>
				</svg>
			</Box>
			<Node
				isLoading={isLoading}
				positions={'right'}
				text={rightCardValue}
				isEdit={isEdit}
				onInputChange={onInputChange}
			/>
		</Box>
	)
}

const Section2 = (props: Props) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [isEdit, setIsEdit] = useState(false)
	// State to store edited values temporarily
	const [editCardValue, setEditCardValue] = useState({
		left: props.leftCardValue,
		center: props.centerCardValue,
		right: props.rightCardValue,
	})
	const handleInputChange = (key: string, value: string) => {
		setEditCardValue((prev) => ({ ...prev, [key]: value }))
	}

	useEffect(() => {
		setEditCardValue({
			left: props.leftCardValue,
			center: props.centerCardValue,
			right: props.rightCardValue,
		})
	}, [props])

	const buildResponseItem = (solution: string) => {
		if (!solution) return

		const stringSol = solution
			.split(/(\d.)/)
			?.splice(1)
			.filter((x) => isNaN(parseInt(x)))
		return (
			<Box bgcolor={'#FFDDE7'} padding={'1rem'}>
				<ol data-testid="gen-ai-response-list">
					{stringSol.map((el: string, i: number) => (
						<Typography
							fontSize={16}
							color="black"
							component="li"
							sx={{ opacity: 0.87 }}
							key={`ai-solution-${i}`}
							variant="body1"
							style={{
								margin: '10px 0 10px 0',
							}}
						>
							{el}
						</Typography>
					))}
				</ol>
			</Box>
		)
	}

	return (
		<>
			<Section2Title />
			<Box sx={styles.mainContainer}>
				<Box marginRight={'3rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					gap="2rem"
					flexGrow="1"
					paddingY={{ xs: '0', sm: '1rem' }}
				>
					<Box
						display={'flex'}
						flexDirection={{
							xs: 'column',
							sm: 'row',
						}}
						justifyContent={'space-between'}
						alignItems={{
							xs: 'flex-start',
							sm: 'center',
						}}
						gap={'1rem'}
					>
						<Typography>
							How can both extremes of the following prompt
							inspire new ideas for your topic?
						</Typography>
						<Box
							display={'flex'}
							flexDirection={'row'}
							justifyContent={'center'}
							alignItems={'center'}
							gap={'1rem'}
						></Box>
					</Box>
					<Box display="flex" justifyContent={'flex-end'}>
						<ButtonWithIconComponent
							sx={{ padding: 0 }}
							disabled={props.isLoading}
							iconSvg={refreshIcon}
							text="REFRESH PROMPT"
							data-testid={`${TEST_ID}__refresh-button`}
							onClickAction={props.onRefreshPromptClick}
						/>
					</Box>
					<Box sx={styles.cardsContainer}>
						{isMobile
							? mobileCards(
									props.isLoading,
									isEdit,
									editCardValue.left,
									editCardValue.center,
									editCardValue.right,
									handleInputChange
								)
							: desktopAndTabletCards(
									props.isLoading,
									isEdit,
									editCardValue.left,
									editCardValue.center,
									editCardValue.right,
									handleInputChange
								)}
					</Box>
					{isEdit ? (
						<Box
							display="flex"
							sx={{
								flexDirection: {
									xs: 'column',
									sm: 'row',
								},
							}}
							gap="32px"
						>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__submit-idea-button`}
								onClick={() => {
									setIsEdit(false)
								}}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								disabled={props.isLoading}
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__view-submit-ideas-button`}
								onClick={() => {
									setIsEdit(false)
									setEditCardValue({
										left: props.leftCardValue,
										center: props.centerCardValue,
										right: props.rightCardValue,
									})
								}}
							>
								<Typography>Cancel</Typography>
							</Button>
						</Box>
					) : (
						<Box display={'flex'} justifyContent={'flex-end'}>
							<ButtonWithIconComponent
								disabled={props.isLoading}
								iconSvg={editIcon}
								text="EDIT PROMPT"
								data-testid={`${TEST_ID}-edit-prompt`}
								onClickAction={() => setIsEdit(!isEdit)}
							/>
						</Box>
					)}
					<Typography>
						Unsure of how to apply the prompt to your innovation
						topic?
					</Typography>
					<GeneratedExampleDropdown
						isLoading={props.isAiLoading}
						gameTypeEnum={GAME_TYPE.TEETER_TOTTER}
						headerText={''}
						customResponse={buildResponseItem(
							props.currentResponses
						)}
					/>
				</Box>
			</Box>
		</>
	)
}
export default Section2
