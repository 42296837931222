export const USER_MANAGEMENT_BASE_URL: string | undefined =
	process.env.USER_MANAGEMENT_ENV_API_BASE_URL
export const USER_POOL_ID: string | undefined =
	process.env.REACT_APP_AWS_USER_POOL_ID
export const USER_POOL_WEB_CLIENT_ID: string | undefined =
	process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
export const AWS_IDENTITY_POOL: string | undefined =
	process.env.REACT_APP_AWS_IDENTITY_POOL
export const AWS_IDENTITY_PROVIDER: string | undefined =
	process.env.REACT_APP_AWS_IDENTITY_PROVIDER
export const AWS_REGION: string | undefined = process.env.REACT_APP_AWS_REGION
export const AWS_DOMAIN: string | undefined = process.env.REACT_APP_AWS_DOMAIN
export const REACT_APP_AZURE_AD_TENANT_URL: string | undefined =
	process.env.REACT_APP_AZURE_AD_TENANT_URL
export const REACT_APP_AZURE_AD_CLIENT_ID: string | undefined =
	process.env.REACT_APP_AZURE_AD_CLIENT_ID
export const MIXPANEL_TOKEN: string | undefined =
	process.env.REACT_APP_MIXPANEL_TOKEN
export const isLocalEnvironment: boolean =
	process.env.NODE_ENV === 'development'
