import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import MenuIcon from '@mui/icons-material/Menu'
import {
	AppBar,
	Box,
	Button,
	Container,
	Drawer,
	Grid,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Popper,
	Toolbar,
	Typography,
} from '@mui/material'

import { useAuth } from 'contexts/AuthProvider'
import { useMixpanelContext } from 'contexts/MixpanelProvider'
import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'

import { ArcLabsLogo } from 'assets/ArcLabsLogo'
import { LOCAL_STORAGE_KEYS } from 'assets/constants'
import newLogo from 'assets/images/newLogo.svg'
import {
	ARC_LABS,
	HOME_PAGE,
	PUBLISHED_GAMES,
	SUBMITTED_IDEAS,
} from 'assets/routes'

import { styles } from './ArcNavBar.styles'

export const TEST_ID = 'arc-navbar'

const generateSiteLinks = (
	games: Array<string>,
	pathname: string,
	handleProtectedPathClick: (path: string) => void,
	isMobile: boolean
) =>
	games.map((game) => {
		const isCurrentPage = pathname === game ? 'current-page' : ''

		if (game === '/' && !isMobile) {
			return ''
		}

		const gameText = game.replace(/[^a-zA-Z]/g, ' ') // converts route to readable text

		return (
			<ListItem key={game}>
				<ListItemButton>
					<Link
						data-testid={`${gameText
							.trim()
							.replace(' ', '-')}-link${
							isMobile ? '--mobile' : ''
						}`}
						onClick={() => handleProtectedPathClick(game)}
						sx={{ textDecoration: 'none' }}
					>
						{game === '/' && isMobile ? (
							<ListItemText
								primary="home"
								className={isCurrentPage}
								sx={{
									...styles.navItemText,
									textTransform: 'lowercase',
								}}
							/>
						) : (
							<ListItemText
								className={isCurrentPage}
								primary={gameText}
								sx={styles.navItemText}
							/>
						)}
					</Link>
				</ListItemButton>
			</ListItem>
		)
	})

const getGivenNameSalutations = (
	firstName: string,
	lastName?: string | null
) => {
	return firstName !== 'login' && lastName
		? `Hello, ${firstName} ${lastName}!`
		: !lastName
			? `Hello, ${firstName}!`
			: firstName
}

const loginOrSalutationsButton = (
	authorized: boolean,
	firstName: string,
	clickAction: () => void,
	isMobile: boolean,
	handleMouseOver: (event: React.MouseEvent<HTMLElement>) => void,
	lastName?: string | null
) => (
	<ListItem
		key="drawer-nav-login-or-account"
		disablePadding
		onClick={() => {
			!authorized && clickAction()
		}}
		sx={styles.loginOrSalutations}
	>
		<ListItemButton
			disableGutters
			data-testid={`${TEST_ID}-login-button${isMobile ? '--mobile' : ''}`}
			onMouseOver={handleMouseOver}
		>
			<Typography sx={styles.navItemText} variant="body1">
				{getGivenNameSalutations(firstName, lastName)}
			</Typography>
		</ListItemButton>
	</ListItem>
)

const logoutPopper = (
	anchorEl: HTMLElement,
	handlePopperClickAway: () => void,
	logoutUser: () => Promise<void>
) => {
	const open = Boolean(anchorEl)
	const id = open ? 'logout-popper' : undefined

	return (
		<ClickAwayListener onClickAway={handlePopperClickAway}>
			<Popper
				id={id}
				open={open}
				anchorEl={anchorEl}
				style={{ zIndex: 3 }}
			>
				<Button
					variant="outlined"
					onClick={logoutUser}
					sx={styles.logoutButton}
				>
					Logout
				</Button>
			</Popper>
		</ClickAwayListener>
	)
}

function ArcNavBar() {
	const { pathname } = useLocation()
	const { isAuthenticated, user, userSignOut } = useAuth()
	const { showModal } = useModalContext()
	const [mobileOpen, setMobileOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const navigate = useNavigate()
	const mixpanel = useMixpanelContext()

	const games = [HOME_PAGE, SUBMITTED_IDEAS, PUBLISHED_GAMES]

	const openLoginModal = useCallback(() => {
		// showModal(MODAL_TYPES.ONE_CLICK_LOGIN_MODAL)
		showModal(MODAL_TYPES.LOGIN_MODAL)
	}, [showModal])

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState)
	}

	const handleProtectedPathClick = (path = '') => {
		if (!isAuthenticated) {
			openLoginModal()
			window.localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH, path)
			return
		}
		window.scrollTo(0, 0)
		navigate(path)
	}

	useEffect(() => {
		mixpanel?.track_pageview()
	}, [pathname, mixpanel])

	useEffect(() => {
		const isGuestUser = !user?.session?.idToken?.payload?.given_name
		if (!user?.session) {
			//	logged out user, reset identity
			// 	Reference: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#call-reset-at-logout
			mixpanel?.reset()
		} else if (!isGuestUser && user?.session?.accessToken?.payload?.sub) {
			// 	Reference: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#identify
			mixpanel?.identify(String(user?.session?.accessToken?.payload?.sub))
		}
	}, [user, mixpanel])

	const handleSalutationsHover = (event: React.MouseEvent<HTMLElement>) => {
		if (isAuthenticated) setAnchorEl(event.currentTarget)
	}

	const handleSalutationsLeave = () => {
		setAnchorEl(null)
	}

	const handleLogout = async () => {
		await userSignOut()
		handleSalutationsLeave()
		window.location.assign(HOME_PAGE)
	}

	return (
		<Grid container sx={styles.NavBarGridContainer}>
			<AppBar position="static" sx={styles.AppBar}>
				<Container maxWidth={false} disableGutters>
					<Toolbar
						sx={{
							height: '6.6rem',
						}}
					>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							<Box display="inline-block">
								<Link
									color="inherit"
									data-testid="homeButton"
									onClick={() =>
										handleProtectedPathClick(HOME_PAGE)
									}
									sx={{
										display: { xs: 'flex' },
										mr: 1,
										cursor: 'pointer',
									}}
								>
									<img
										alt="new-logo"
										data-testid={`${TEST_ID}-logo`}
										src={newLogo as unknown as string}
									/>
								</Link>
							</Box>
						</Box>
						<Box
							sx={{
								flexGrow: 0,
								display: { xs: 'none', md: 'flex' },
							}}
						>
							<List sx={styles.navList}>
								{generateSiteLinks(
									games,
									pathname,
									handleProtectedPathClick,
									false
								)}
								{loginOrSalutationsButton(
									isAuthenticated,
									user?.firstName ?? '',
									openLoginModal,
									false,
									handleSalutationsHover,
									user?.lastName
								)}
								{anchorEl &&
									logoutPopper(
										anchorEl,
										handleSalutationsLeave,
										handleLogout
									)}
							</List>
						</Box>
						<Box
							sx={{
								flexGrow: 0,
								display: { xs: 'flex', md: 'none' },
							}}
						>
							<IconButton
								aria-label="open drawer"
								color="inherit"
								data-testid="toggle-drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ display: { md: 'none' } }}
							>
								<MenuIcon />
							</IconButton>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<nav>
				<Drawer
					anchor="right"
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					SlideProps={{
						direction: 'left',
					}}
					sx={styles.drawer}
				>
					<Box
						sx={{
							flexGrow: 0,
							display: { xs: 'flex', md: 'none' },
						}}
					>
						<IconButton
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={styles.drawerCloseButton}
						>
							<CloseSharpIcon />
						</IconButton>
					</Box>
					<Box
						display="flex"
						flexGrow={3}
						onClick={handleDrawerToggle}
					>
						<List sx={styles.navListMobile}>
							{generateSiteLinks(
								games,
								pathname,
								handleProtectedPathClick,
								true
							)}
							{loginOrSalutationsButton(
								isAuthenticated,
								user?.firstName ?? '',
								openLoginModal,
								true,
								handleSalutationsHover,
								user?.lastName
							)}
						</List>
					</Box>
					<Box sx={styles.arcLabsLogoContainer}>
						<Link
							color="inherit"
							data-testid={`${TEST_ID}_arc-labs_button`}
							href={ARC_LABS}
							sx={styles.arcLabsLogoLink}
						>
							<ArcLabsLogo
								height="67px"
								width="127px"
								viewBox="0 0 127 67"
							/>
						</Link>
					</Box>
				</Drawer>
			</nav>
		</Grid>
	)
}

export default ArcNavBar
