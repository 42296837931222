import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { TOPIC_DROPDOWN_VALUES } from 'enums/TopicTypeEnum'

import { SimonSaysPrompt } from './ideaModels'

export type Response = {
	prompt: SimonSaysPrompt
	resetPreviousConnectionPoints: boolean
	resetPreviousPrompts: boolean
}

export type Conversation = {
	content: string
	role: string
}

export function SimonSaysModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [currentResponses, setCurrentResponses] = useState<string[]>([])
	const [customerJourney, setCustomerJourney] = useState<string>('')
	const [customerProblem, setCustomerProblem] = useState<string>('')
	const [customerType, setCustomerType] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const [imageAvailable, setImageAvailable] = useState<boolean>(false)
	const [imageData, setImageData] = useState<string | null>(null)
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [innovationTopic, setInnovationTopic] = useState<string>('')
	const [previousRandomElements, setPreviousRandomElements] = useState<
		string[]
	>([])
	const [promptConversation, setPromptConversation] = useState<
		Conversation[]
	>([])
	const [randomElement, setRandomElement] = useState<string>('')
	const [responseConversation, setResponseConversation] = useState<
		Conversation[]
	>([])
	const [previousPrompts, setPreviousPrompts] = useState<number[]>([])
	const [previousConnectionPoints, setPreviousConnectionPoints] = useState<
		string[]
	>([])

	const gameTypeId = GAME_TYPE.SIMON_SAYS.value

	const getRandomPromptsQueryObj = () => {
		return { previousPrompts, previousConnectionPoints }
	}

	const setOnlyRandom = (prompts: string[], reset: boolean) => {
		if (!prompts.length) return
		setRandomElement(prompts[0])
		setPreviousRandomElements([
			...prompts,
			...(reset ? [] : previousRandomElements),
		])
	}

	const setPromptResponse = (response: Response) => {
		const prompt = response.prompt

		setPreviousPrompts([...new Set([...previousPrompts, prompt.promptId])])
		setPreviousConnectionPoints([
			...new Set([
				...previousConnectionPoints,
				prompt.customerConnectionPoint,
			]),
		])

		setRandomElement(prompt.customerConnectionPoint)
		setCustomerType(prompt.customerType)
		setCustomerProblem(prompt.customerProblem)
	}

	const reset = () => {
		setInnovationCompany('')
		setPromptConversation([])
		setCustomerProblem('')
		setCustomerType('')
		setRandomElement('')
		setCustomerJourney('')
		resetSoft()
	}

	const resetSoft = () => {
		setResponseConversation([])
		setImageData(null)
		setImageAvailable(false)
		setAuthorIdeation('')
		setCurrentResponses([])
	}

	const resetImage = () => {
		setImageAvailable(false)
		setImageData(null)
	}

	const setCustomerValues = (
		customerType: string,
		customerProblem: string
	) => {
		setCustomerType(customerType)
		setCustomerProblem(customerProblem)
		setPreviousRandomElements([randomElement, ...previousRandomElements])
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			authorIdeation,
		}
	}

	const getUnselectedStages = (innovationTopic: string) => {
		return TOPIC_DROPDOWN_VALUES.filter(
			(topic) => topic !== innovationTopic
		)
	}

	const promptToJSON = () => {
		return {
			innovationTopic,
			innovationCompany,
			conversation: promptConversation,
			previousRandomElements,
			customerJourney,
			unselectedStages: getUnselectedStages(innovationTopic),
		}
	}

	const responseToJSON = () => {
		return {
			innovationTopic,
			innovationCompany,
			randomElement,
			customerType,
			customerProblem,
			conversation: responseConversation,
		}
	}

	const imageToJSON = () => {
		return {
			gameTypeId,
			customerProblem,
			randomElement,
		}
	}

	const buttonDisabled = () => {
		if (
			authorIdeation.length === 0 ||
			innovationCompany.length === 0 ||
			customerJourney.length === 0 ||
			customerType.length === 0 ||
			customerProblem.length === 0 ||
			randomElement.length === 0
		)
			return true
		else return false
	}

	const generateScenarioDisabled = () =>
		!innovationCompany.length || !customerJourney.length

	const generateNewScenarioDisabled = () =>
		generateScenarioDisabled() ||
		!customerProblem ||
		!customerType ||
		!randomElement

	const generateImageDisabled = () =>
		generateNewScenarioDisabled() ||
		!customerType.length ||
		!customerProblem.length ||
		!randomElement.length

	const submitIdeasDisabled = () => {
		if (generateScenarioDisabled() || !authorIdeation) return true
		return false
	}

	return {
		innovationCompany,
		setInnovationCompany,
		innovationTopic,
		setInnovationTopic,
		customerJourney,
		setCustomerJourney,
		customerType,
		customerProblem,
		randomElement,
		setPromptResponse,
		imageData,
		setImageData,
		imageAvailable,
		setImageAvailable,
		authorIdeation,
		setAuthorIdeation,
		promptConversation,
		setPromptConversation,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		reset,
		resetSoft,
		resetImage,
		gameToJSON,
		promptToJSON,
		responseToJSON,
		imageToJSON,
		buttonDisabled,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		setGameId,
		generateImageDisabled,
		submitIdeasDisabled,
		setOnlyRandom,
		setCustomerValues,
		getRandomPromptsQueryObj,
		setPreviousPrompts,
		setPreviousConnectionPoints,
	}
}
