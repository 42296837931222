import React, {
	FC,
	ReactElement,
	ReactNode,
	cloneElement,
	useMemo,
} from 'react'

import { useAuth } from 'contexts/AuthProvider'

import HomePage from 'pages/HomePage/HomePage'
import PublishedGame from 'pages/PublishedGame'

import { PUBLISHED_GAMES } from 'assets/routes'

import { AUTH_ROLE } from 'enums/AuthRolesEnum'

export type Props = {
	children: ReactNode
	guestUserAllowed?: boolean
}

const RequireAuth: FC<Props> = ({ children, guestUserAllowed = true }) => {
	const { isAuthenticated, isAuthenticating, user } = useAuth()
	const name = user?.firstName
	const isGuestUser =
		user?.session?.idToken?.payload?.role_id !== AUTH_ROLE.ADMIN

	const canAccess = useMemo(() => {
		// for not logged in user, only guestUserAllowed pages are accessible
		if (!isAuthenticated) return guestUserAllowed
		// for slalom logged in user, all pages are accessible
		if (!isGuestUser) return true
		// for guest logged in user, only guestUserAllowed pages are accessible
		if (guestUserAllowed) return true

		return false
	}, [isAuthenticated, isGuestUser, guestUserAllowed])

	return (
		<>
			{isAuthenticating ? (
				<>...isAuthenticating </>
			) : canAccess ? (
				cloneElement(children as ReactElement, {
					name,
				})
			) : window.location.href.includes(PUBLISHED_GAMES + '/') ? (
				<PublishedGame />
			) : (
				<HomePage />
			)}
		</>
	)
}

export default RequireAuth
